export default {
  loginUrl: 'https://app.qmclouds.com/login',
  qualityUrl: '/quality/',
  qualitySystemUrl: '/quality-system/',
  softwareUrl: '/software/',
  environmentalSystem: '/environmental-system/',
  occupationalSafetySystem: '/occupational-safety-system/',
  articlePageUrl: '/articles/',
  deviationReportingArticle: '/deviation-reporting/',
  improvingQualityArticle: '/improving-quality/',
  implementedQualitySystemArticle:
    '/how-to-implement-the-quality-system-in-your-company',
  whyDoYouNeedQualityManagementSystemArticle: '/why-do-you-need-quality-system',
  whatDoesItCostToBuildQualitySystemArticle:
    '/what-does-it-cost-to-build-quality-system',
  stepByStepInstructionsForBuildingQualitySystemArticle:
    '/step-by-step-instructions-for-building-quality-system',
  whatIsQualitySystemArticle: '/what-is-quality-system',
  qualityManualUrl: '/quality-manual/',
  priceUrl: '/pricing/',
  home: '/',
};
