import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import blueLogo from '../images/qmc-logo-blue.svg';
import whiteLogo from '../images/qmc-logo-white.svg';
import * as supportedLanguages from '../supported-languages';
import urls from './urls';

interface Props {
  locale: string;
}

const Header = ({ locale }: Props) => {
  const [activeClass, setActiveClass] = useState('hide-header-bg');
  const { t } = useTranslation();

  const setClassToHeader = useCallback(() => {
    let localActiveClass = 'normal';
    if (window.scrollY === 0) {
      localActiveClass = 'hide-header-bg';
    }
    setActiveClass(localActiveClass);
  }, [setActiveClass]);

  useEffect(() => {
    setClassToHeader();
    window.addEventListener('scroll', setClassToHeader);
  }, [setClassToHeader]);

  return (
    <Navbar
      bg="light"
      variant="light"
      fixed="top"
      expand="xl"
      className={activeClass}
    >
      <Link to="/" className="navbar-brand">
        {activeClass === 'normal' ? (
          <img src={blueLogo} alt={t('headerAlt')} className="img" />
        ) : (
          <img src={whiteLogo} alt={t('headerAlt')} className="img" />
        )}
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Link to={urls.home} className="nav-link" activeClassName="active">
            {t('headerHome')}
          </Link>

          {locale === 'fi' ? (
            <NavDropdown
              title={t('headerQualitySystem')}
              id="dropdown"
              alignRight
            >
              <NavDropdown.Item href={urls.qualitySystemUrl}>
                {t('headerQualitySystem')}
              </NavDropdown.Item>
              <NavDropdown.Item href={urls.qualityUrl}>
                {t('headerQuality')}
              </NavDropdown.Item>
              <NavDropdown.Item href={urls.qualityManualUrl}>
                {t('headerQualityManual')}
              </NavDropdown.Item>
              <NavDropdown.Item href={urls.softwareUrl}>
                {t('headerSoftware')}
              </NavDropdown.Item>
            </NavDropdown>
          ) : null}

          {locale === 'fi' ? (
            <Link
              to={urls.environmentalSystem}
              className="nav-link"
              activeClassName="active"
            >
              {t('headerEnvironmentalSystem')}
            </Link>
          ) : null}
          {locale === 'fi' ? (
            <Link
              to={urls.occupationalSafetySystem}
              className="nav-link"
              activeClassName="active"
            >
              {t('headerOccupationalSafetySystem')}
            </Link>
          ) : null}
          {locale === 'fi' ? (
            <Link
              to={urls.articlePageUrl}
              className="nav-link"
              activeClassName="active"
            >
              {t('headerArticles')}
            </Link>
          ) : null}
          {locale === 'fi' ? (
            <Link
              to={urls.priceUrl}
              className="nav-link"
              activeClassName="active"
            >
              {t('headerPricing')}
            </Link>
          ) : null}
          <Nav.Link href={urls.loginUrl} className="nav-link">
            {t('headerLogin')}
          </Nav.Link>

          <NavDropdown title="Language" id="dropdown" alignRight>
            {supportedLanguages.availableLocales.map((l) => {
              let url = `/${l.value}/`;
              if (l.value === supportedLanguages.defaultLocales.value)
                url = '/';
              return (
                <NavDropdown.Item key={l.value} href={url}>
                  {l.text}
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
